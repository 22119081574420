import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `hsla(0,0%,100%,.95)`,
      marginBottom: `0`,
    }}
  >
    <div
      className="header"
      style={{
        margin: `0 auto`,
        maxWidth: 960,
      }}
    >
      <h1
        style={{
          margin: 0,
          lineHeight: 1,
          fontSize: `36px`,
        }}
      >
        <Link
          to="/about"
          style={{
            display: `inline-block`,
            textDecoration: `none`,
            fontFamily: `Brandon Text`,
            fontWeight: 400,
            lineHeight: 1,
            textTransform: `uppercase`,
            color: `#393939`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
